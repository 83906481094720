@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap");

html {
  scroll-behavior: smooth;
}

* {
  font-family: "Poppins", serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  /*background: rgb(238, 174, 202);*/
  background:  hsl(240, 2%, 12%);
  background: radial-gradient(
    circle,
    /*hsl(0, 0%, 22%) 100%,*/
    rgba(238, 174, 202, 1) 3%,
    rgba(148, 187, 233, 1) 97%

  /*hsl(0, 0%, 19%) 100%*/
  );
}
/*custom scrollbar*/
::-webkit-scrollbar {
  width: 6px;
  background: #acb7e1;
}
::-webkit-scrollbar-thumb {
  background: #4577af;
  border-radius: 10px;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.shape {
  opacity: 60%;
  position: absolute;
  z-index: -1;
  height: 10rem;
  width: 10rem;
}

.wave {
  animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
  animation-duration: 2.5s; /* Change to speed up or slow down */
  animation-iteration-count: infinite; /* Never stop waving :) */
  transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } /* The following five values can be played with to make the waving more or less extreme */
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } /* Reset for the last half to pause */
  100% {
    transform: rotate(0deg);
  }
}

.img img {
  max-width: 400px;
  /* animation: animate 5s linear infinite; */
}

@media only screen and (max-width: 1100px) {
  .img img {
    display: none;
  }
}

/* @keyframes animate {
    0%,100%{
        transform: translateY(50px);
    }
    50%{
        transform: translateY(100px);
    }
} */

section {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 50px;
}

@media (max-width: 900px) {
  section {
    padding: 50px 10px 10px;
  }
}

.container {
  position: relative;
  width: calc(100% - 100px);
  min-height: 300px;
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  margin-top: 60px;
}

@media (max-width: 1100px) {
  .container {
    position: relative;
    width: calc(100% - 20px);
  }
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
    margin-top: 120px;
    height: auto;
    padding: 10px;
    width: calc(100% - 75px);
  }
}

@media (max-width: 380px) {
  .container {
    flex-direction: column;
    margin-top: 80px;
    height: auto;
    padding: 10px;
    width: calc(100% - 0px);
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  header {
    position: fixed;
    top: 10px;
    right: 90px;
    left: 90px;
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    backdrop-filter: blur(1rem);
    -webkit-backdrop-filter: blur(1rem);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    z-index: 90;
    width: calc(100% - 180px);
  }

  @media (max-width: 900px) {
    header {
      position: fixed;
      top: 10px;
      /* top: -0px; */
      /* right: 0px;
      left: 0px; */
      /* width: 100%; */
      width: calc(100% - 80px);
      flex-direction: column;
      padding: 20px;
    }
    header ul {
      margin-top: 10px;
      flex-wrap: wrap;
      justify-content: center;
    }

    header ul li {
      margin: 2px;
    }

    header ul li a {
      display: inline-block;
    }
  }
}
@media (max-width: 380px) {
  header {
    position: relative;
    top: -120px;
    right: 0px;
    left: 0px;
    width: 100%;
    flex-direction: column;
    padding: 20px;
  }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  header {
    position: absolute;
    top: -40px;
    right: 20px;
    left: 20px;
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
    opacity: 0.9;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    backdrop-filter: blur(1rem);
    -webkit-backdrop-filter: blur(1rem);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
  }

  @media (max-width: 900px) {
    header {
      position: relative;
      top: -60px;
      right: 0px;
      left: 0px;
      width: 100%;
      flex-direction: column;
      padding: 20px;
    }
    header ul {
      margin-top: 10px;
      flex-wrap: wrap;
      justify-content: center;
    }

    header ul li {
      margin: 2px;
    }

    header ul li a {
      display: inline-block;
    }
  }
}

header .logo {
  color: black;
  font-weight: 700;
  text-decoration: none;
  font-size: 2rem;
}

header ul {
  display: flex;
}

header ul li {
  list-style: none;
  margin: 0 10px;
}

header ul li a {
  text-decoration: none;
  font-weight: 500;
  color: black;
  padding: 8px 15px;
  border-radius: 1rem;
  transition: 500ms ease;
}

header ul li a.hover,
header ul li a.active {
  background: hsla(0, 0%, 100%, 1);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.content {
  display: flex;
  z-index: 1;
  margin-top: 50px;
  align-items: center;
}

@media (max-width: 900px) {
  .content {
    flex-direction: column;
    transform: translateX(0px);
    max-width: 100%;
    margin-top: 10px;
  }
}
@media (max-width: 500px) {
  .content {
    flex-direction: column;
    transform: translateX(0px);
    max-width: 100%;
    margin-top: 10px;
  }
}
@media (max-width: 440px) {
  .content {
    flex-direction: column;
    transform: translateX(0px);
    max-width: 100%;
    margin-top: 50px;
  }
}
@media (max-width: 380px) {
  .content {
    flex-direction: column;
    transform: translateX(0px);
    max-width: 100%;
    margin-top: -60px;
  }
}

.column {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  backdrop-filter: blur(1rem);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  padding: 40px;
}

.column-2 {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  backdrop-filter: blur(1rem);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  padding: 40px;
  margin-inline: 50px;
  margin-right: 0px;
}

@media (max-width: 900px) {
  .column-2 {
    position: relative;
    right: 0px;
    left: 0px;
    width: 100%;
    margin-top: 80px;
    margin-right: 0px;
    margin-left: 0px;
  }
}

.column h2 {
  font-size: 3rem;
  color: black;
}
.column-2 h2 {
  font-size: 3rem;
  color: black;
}

.column h3 {
  font-size: 1.5rem;
  /* color:cadetblue; */
}

.column p {
  font-size: 1.5rem;
  color: black;
  line-height: 2.25rem;
}

@media (max-width: 900px) {
  .column h2 {
    font-size: 2.5rem;
  }
  .column-2 h2 {
    font-size: 2.8rem;
  }
  .column p {
    font-size: 1.2rem;
  }
  .mob-h2 {
    font-size: 2.8rem !important;
  }
  .mob-connect {
    font-size: 2.1rem !important;
  }
}

.column p .column-p {
  font-size: 1rem;
}

.column a {
  display: inline-block;
  padding: 0.9rem 1.8rem;
  color: black;
  margin-top: 20px;
  margin-right: 10px;
  border-radius: 30px;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
  background: hsla(0, 0%, 100%, 0.25);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
  transition: 500ms ease;
}

.column a:hover {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0 5px 10px rgba(255, 255, 255, 0.05);
}

.socialsites {
  position: fixed;
  top: calc(50% - 100px);
  right: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (min-width: 900px) {
  .enabledisable {
    display: none;
  }
}

@media (max-width: 1100px) {
  .socialsites {
    position: fixed;
    right: 35px;
  }
}

@media (max-width: 900px) {
  .socialsites {
    position: relative;
    flex-direction: row;
    margin: 20px;
    gap: 10px;
    left: 0px;
    right: 0px;
    /* right: 5px; */
  }
}

.socialsites li {
  list-style: none;
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .socialsites li a {
    text-decoration: none;
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.2);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(1rem);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 5px 0;
    transition: 0.5s ease;
  }
}
@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .socialsites li a {
    text-decoration: none;
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
    opacity: 0.9;
    backdrop-filter: blur(1rem);
    -webkit-backdrop-filter: blur(1rem);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 5px 0;
    transition: 0.5s ease;
  }
}

.socialsites li a:hover {
  background: rgba(255, 255, 255, 0.5);
}

.fa-github {
  color: #333;
}

.fa-linkedin {
  color: #0077b5;
}

.fa-facebook {
  color: #1877f2;
}

.fa-instagram {
  color: #e1306c;
}

.grid {
  display: grid;
  grid-template-columns: 30% 70%;
  margin-top: 5px;
  padding-top: 4rem;
}

.dev-icons ul {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0;
  margin-top: 0;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

/* .software-skill-inline i{
    color:#868e96
}

.software-skill-inline i:hover{
    color:#645beb
} */

.software-skill-inline p {
  color: #868e96;
  font-size: 10px;
}

.software-skill-inline i:hover p {
  color: #645beb;
}

.c i {
  color: #868e96;
}

.c i:hover {
  color: #2a9fc9;
}

.cplusplus i {
  color: #868e96;
}

.cplusplus i:hover {
  color: #9c033a;
}

.mysql i {
    color: #868e96;
}

.mysql i:hover {
  color: #126fd2;
}

.mongodb i {
  color: #868e96;
}

.mongodb i:hover {
  color: #00684A;
}

.html5 i {
  color: #868e96;
}

.html5 i:hover {
  color: #e54d26;
}

.css3 i {
  color: #868e96;
}

.css3 i:hover {
  color: #3d8fc6;
}

.javascript i {
  color: #868e96;
}

.javascript i:hover {
  color: #f0db4f;
}

.python i {
  color: #868e96;
}

.python i:hover {
  color: #ffd845;
}

.flask i {
  color: #868e96;
}

.flask i:hover {
  color: #010101;
}

.java i {
  color: #868e96;
}

.java i:hover {
  color: #b03232;
}

.linux i {
  color: #868e96;
}

.linux i:hover {
  color: #231d1d;
}

.selenium i {
  color: #868e96;
}

.selenium i:hover {
  color: #43b02a;
}

.github i {
    color: #868e96;
}

.github i:hover {
  color: #333;
}

.highlight-yellow-brush {
  background: url("../../assets/yellow-brushstroke.png") no-repeat;
  background-size: 100% 95%;
  padding: 8px 0;
}

.realistic-marker-highlight {
  position: relative;
  color: white;
}

.realistic-marker-highlight:before {
  content: "";
  background-color: #ff6db7;
  width: 100%;
  height: 1em;
  position: absolute;
  z-index: -1;
  filter: url(#marker-shape);
  left: -0.25em;
  top: 0.1em;
  padding: 0 0.25em;
}

.experience-cards-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  grid-gap: 1rem 1rem;
  gap: 1rem 1rem;
  margin-top: 25px;
}

.experience-card {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  backdrop-filter: blur(1rem);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  transition: 0.5s ease;
}

.experience-card,
.experience-card-dark {
  position: relative;
  box-shadow: 0 10px 30px -15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.experience-card-dark {
  background-color: #171c28;
}

.experience-card:hover {
  box-shadow: 0 20px 30px -10px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.5);
}

.experience-banner {
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 150%;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5));
  border-radius: 10px 10px 0 0;
  -webkit-filter: blur(0);
  -moz-filter: blur(0);
  -o-filter: blur(0);
  -ms-filter: blur(0);
  filter: blur(0);
  height: 11rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  -webkit-justify-content: center;
  justify-content: center;
}

.experience-blurred_div {
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.2));
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
  height: 11rem;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 10px 10px 0 0;
}

.experience-div-company {
  position: absolute;
  background: transparent;
  height: 9rem;
  top: 0;
  left: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.experience-text-company {
  padding: 1.5rem;
  margin: 0;
  color: #fff;
  font-size: 25px;
  text-align: center;
  font-weight: 550;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.experience-roundedimg {
  position: absolute;
  object-fit: contain;
  left: 0;
  right: 0;
  top: 7rem;
  margin-left: auto;
  margin-right: auto;
  width: 7rem;
  height: 7rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}
/*.experience-roundedimg {*/
/*    position: absolute;*/
/*    object-fit: contain; !* Ensures that the whole image is visible *!*/
/*    left: 50%; !* Center the image horizontally *!*/
/*    top: 7rem;*/
/*    transform: translateX(-50%); !* Shift it to the left by half of its width to truly center it *!*/
/*    width: 7rem;*/
/*    height: 7rem;*/
/*    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.3);*/
/*    border-radius: 50%; !* Gives a circular shape *!*/
/*    border: none; !* Optional: removes any border if present *!*/
/*    padding: 0; !* Optional: removes any padding if present *!*/
/*}*/

.experience-text-details {
  padding: 1.5rem;
  margin-top: 2rem;
}

.experience-text-role {
  text-align: center;
  color: #000;
  font-weight: 400;
  font-size: 25px;
  margin: 0;
  padding-top: 1.5rem;
  line-height: normal;
}

.experience-text-date {
  text-align: center;
  color: #000;
  font-size: 20px;
  margin: 0;
  padding-top: 1rem;
  font-weight: 400;
}

.subTitle {
  color: #868e96;
}

.experience-text-desc {
  text-align: center;
}

.logo-svg {
  height:5.75rem;
  width: 20.375rem;
}

.low-highlight-cyan {
  letter-spacing: 2px;
  padding: 0 5px;
  background: linear-gradient(to bottom, transparent 50%, #00ead3 50%);
}

.low-highlight-pink {
  letter-spacing: 2px;
  padding: 0 5px;
  background: linear-gradient(to bottom, transparent 50%, #ff6db7 50%);
}

.low-highlight-skin {
  letter-spacing: 2px;
  padding: 0 5px;
  background: linear-gradient(to bottom, transparent 50%, #fff5b7 50%);
}

.low-highlight-lightyellow {
  letter-spacing: 2px;
  padding: 0 5px;
  background: linear-gradient(to bottom, transparent 50%, #ffd460 50%);
}

.low-highlight-hazeskin {
  letter-spacing: 2px;
  padding: 0 5px;
  background: linear-gradient(to bottom, transparent 50%, #f7d59c 50%);
}

#progress {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  position: fixed;
  bottom: 25px;
  right: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: grid;
  place-items: center;
}
#progress-value {
  display: block;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  background-color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-weight: 600;
  font-size: 16px;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem 1rem;
  margin-top: 25px;
}

.repo-card {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  backdrop-filter: blur(1rem);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  transition: 0.5s ease;
}

.repo-card,
.repo-card-dark {
  position: relative;
  box-shadow: 0 10px 30px -15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.repo-card:hover {
  box-shadow: 0 20px 30px -10px rgba(0, 0, 0, 0.2);
  background: rgba(255, 255, 255, 0.5);
}

.achievement-cards-div {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem 1rem;
  margin-top: 25px;
}

.certificate-card {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  backdrop-filter: blur(1rem);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  padding: 1.5rem;
  transition: all 0.3s ease;
}

.certificate-card:hover {
  box-shadow: 0 20px 30px -10px rgba(0, 0, 0, 0.2);
}

.certificate-image-div {
  position: relative;
  height: 250px;
  overflow: hidden;
  text-align: center;
}

.card-image {
  max-width: 100%;
  height: auto;
  width: 250px;
}

.certificate-detail-div {
  text-align: center;
}

.card-title {
  color: #000;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  margin: 15px 0 0;
}

.card-subtitle {
  color: #666;
  font-size: 17px;
  line-height: 1.5rem;
}

.certificate-card-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}

.certificate-card-footer span.certificate-tag {
  background: #55198b;
  color: #f5f2f4;
  vertical-align: middle;
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 0.75rem;
  height: 2em;
  justify-content: center;
  white-space: nowrap;
  line-height: 1.5;
  margin: 0 0.5rem 0.5rem 0;
  padding: 0 0.75em;
  cursor: pointer;
  transition: 0.2s ease-in;
}

span.certificate-tag:hover {
  background: #8c43ce;
}

footer {
  position: absolute;
  bottom: -40px;
  right: 20px;
  left: 20px;
  padding: 20px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  backdrop-filter: blur(1rem);
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

footer .logo {
  color: black;
  font-weight: 700;
  text-decoration: none;
  font-size: 2rem;
}

footer ul {
  display: flex;
}

footer ul li {
  list-style: none;
  margin: 0 10px;
}

footer ul li a {
  text-decoration: none;
  font-weight: 500;
  color: black;
  padding: 8px 15px;
  border-radius: 1rem;
  transition: 500ms ease;
}

footer ul li a:hover,
footer ul li a.active {
  background: hsla(0, 0%, 100%, 0.25);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
}

.button-top-div {
  position: fixed;
  bottom: 20px;
  right: -24px;
  cursor: pointer;
  padding: 20px 50px;
  display: flex;
  align-items: center;
  z-index: 80;
}

@media (max-width: 900px) {
  .button-top-div {
    position: fixed;
    right: 0px;
  }
}
@media (max-width: 550px) {
  .button-top-div {
    position: fixed;
    right: -35px;
    bottom: 0;
  }
}
@media (max-width: 380px) {
  .button-top-div {
    position: fixed;
    right: -35px;
    bottom: 0;
  }
  #button-top {
    width: 40px;
    height: 40px;
  }
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  #button-top {
    text-decoration: none;
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.5);
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(1rem);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 5px 0;
    transition: 0.5s ease;
  }
}

@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  #button-top {
    text-decoration: none;
    width: 50px;
    height: 50px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0.5;
    box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(1rem);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 5px 0;
    transition: 0.5s ease;
  }
}

#button-top:hover {
  background: rgba(255, 255, 255, 0.8);
}

#button-top::after {
  /* content: "\f077"; */
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  font-size: 2em;
  line-height: 50px;
  color: #fff;
}

#button-top:active {
  background-color: #fff;
}

#button-top.show {
  opacity: 1;
  visibility: visible;
}
